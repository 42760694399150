<template>
	<section :class="mod" v-if="1==0">
		<h2 class="ask-question__title">Вы можете задать вопрос:</h2>
		<ul class="ask-question__list">
			<li class="ask-question__item">
				<div class="ask-question__box">
					<a class="abs-link" href="tel:88002004040"></a>
					<h2>По телефону</h2>
					<div class="ask-card ask-card--phone">
						<p class="phone">8-800-200-40-40</p>
						<p class="sub-text">Бесплатно и круглосуточно</p>
					</div>
				</div>
			</li>
			<li class="ask-question__item">
				<div class="ask-question__box">
					<h2>В приложении</h2>
					<div class="ask-card ask-card--application">
						<p class="sub-text">
							<a href="/static/instruction.pdf" target="_blank">Инструкция по использованию и установке приложений (.pdf)</a>
						</p>
						<ul class="application-links">
							<li><a href="/static/moskva-putinu.apk">Скачать apk</a></li>
<!--							<li><a href="#" target="_blank">NASHSTORE</a></li>-->
							<li><a href="https://apps.rustore.ru/app/ru.imteleport.plp2" target="_blank">RuStore</a></li>
              <li><a href="https://appgallery.huawei.com/#/app/C109734173" target="_blank">AppGallery</a></li>
<!--							<li><a href="#" target="_blank">RuMarket</a></li>-->
							<li><a href="https://play.google.com/store/apps/details?id=ru.rostel.plp3" target="_blank">Google Play</a></li>
<!--              <li><a href="https://apps.apple.com/ru/app/gosuslugi/id6738346719" target="_blank">App Store</a></li>-->
						</ul>
					</div>
				</div>
			</li>
			<li class="ask-question__item">
				<div class="ask-question__box">
					<a class="abs-link" href="https://video.moskva-putinu.ru/" target="_blank"></a>
					<h2>На сайте</h2>
					<div class="ask-card ask-card--site">
						<div class="arrow-link"></div>
					</div>
				</div>
			</li>
			<li class="ask-question__item">
				<div class="ask-question__box">
					<a class="abs-link" href="https://video.moskva-putinu.ru/" target="_blank"></a>
					<h2>По видео</h2>
					<div class="ask-card ask-card--video">
						<div class="arrow-link"></div>
					</div>
				</div>
			</li>
			<li class="ask-question__item">
				<div class="ask-question__box">
					<a class="abs-link" href="sms:04040"></a>
					<h2>По SMS/MMS</h2>
					<div class="ask-card ask-card--sms">
						<p class="phone">0-40-40</p>
						<p class="sub-text">Бесплатно и круглосуточно</p>
					</div>
				</div>
			</li>
			<li class="ask-question__item">
				<div class="ask-question__box">
					<h2>В соц. сетях</h2>
					<div class="ask-card ask-card--social">
						<p><a class="vkontakte-link" href="https://vk.com/moskvaputinu" target="_blank">ВКонтакте</a></p>
						<p><a class="odnoklassniki-link" href="https://ok.ru/moskvaputinu" target="_blank">Одноклассники</a></p>
					</div>
				</div>
			</li>
		</ul>
	</section>
</template>

<script>
export default {
	props:    [
		'type'
	],
	computed: {
		mod() {
			let name    = 'ask-question';
			let classes = [ name ];

			classes.push(`${ name }--${ this.type }`);

			return classes.join(' ');

		}
	}
}
</script>

<style lang="scss">
@use "sass:math";
@import "../assets/scss/config";

.ask-question {

	&--desktop {
	}

	&--mobile {
		display: none;

		@media screen and ($media-mobile) {
			display: block;
		}
	}

	&__title {
		font: 32px/32px 'RF Devi Semi Bold', sans-serif;
		color: $color-blue-dark;
		margin: 0 0 45px;

		@media screen and ($media-mobile) {
			font: 24px/24px 'RF Devi Semi Bold', sans-serif;
			margin: 0 12px 22px;
		}
	}

	&__list {
		display: flex;
		flex-flow: row wrap;
		margin: 0 (-$grid-gutter-half);
		padding: 0;
		list-style: none;

		@media screen and ($media-mobile) {
			flex-flow: row nowrap;
			overflow: auto;
			scroll-snap-type: x mandatory;
			margin: 0 (-$grid-gutter-half) 16px;
			padding: 0 20px;
		}

		&::-webkit-scrollbar {
			display: none;
		}
	}

	&__item {
		box-sizing: border-box;
		padding: 0 $grid-gutter-half;
		flex: 0 0 math.div(1 * 100%, 3);
		margin: 0 0 24px;

		@media screen and ($media-tablet) {
			flex: 0 0 math.div(1 * 100%, 2);
		}

		@media screen and ($media-mobile) {
			flex: 0 0 320px;
			margin: 0;
			scroll-snap-align: center;
			scroll-snap-stop: always;
			padding: 0 4px;
		}
	}

	&__box {
		width: 100%;
		padding-bottom: math.div(256 * 100%, 310);
		border-radius: 16px;
		position: relative;
		overflow: hidden;
		background: rgba($color-light, .9);

		h2 {
			font: 24px/32px 'RF Devi Semi Bold', sans-serif;
			position: absolute;
			top: 0;
			left: 0;
			padding: 22px 32px;
			margin: 0;
			@media screen and ($media-mobile) {
				padding: 16px;
			}
		}

		.abs-link {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
			z-index: 1;
		}
	}
}
</style>