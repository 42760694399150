import { createStore } from 'vuex'
import moment from 'moment'

export default createStore({
	state:     {
		article:  'home',
		stage:    null,
		sections: [
			{
				stages:   [
					'air'
				],
				title:    'Прямой эфир',
				info:     '',
				color:    'red',
				type:     'live',
				buttons:  [
					{
						title: 'Смотреть',
						view:  'live'
					}
				],
				pictures: [
					'images/putin-line.jpg',
				],
				menu:     [
					{
						id:       1,
						title:    'Трансляция Итогов года с Владимиром Путиным',
						view:     'video',
						isActive: true
					},
					{
						id:       2,
						title:    'Трансляция с сурдопереводом Итогов года с Владимиром Путиным',
						view:     'video',
						isActive: false
					}
				]
			},
			{
				stages:   [
					'air',
					'after'
				],
				title:    'Видео',
				info:     '',
				color:    'blue',
				type:     'videos',
				buttons:  [],
				pictures: [
					// 'images/line1.jpg',
					// 'images/line2.jpg',
					// 'images/line3.jpg',
					// 'images/line4.jpg'
				],
				menu:     [
					{
						id:       1,
						title:    'Часть 1',
						view:     'video',
						isActive: true
					},
					{
						id:       2,
						title:    'Часть 2',
						view:     'video',
						isActive: false
					},
					{
						id:       3,
						title:    'Часть 3',
						view:     'video',
						isActive: false
					},
					{
						id:       4,
						title:    'Часть 4',
						view:     'video',
						isActive: false
					}
				]
			},
			{
				stages:   [
					'before'
				],
				title:    'Вопросы',
				info:     '',
				color:    'light',
				type:     'questions',
				buttons:  [],
				pictures: [],
				contents: []
			},
			{
				stages:   [
					'before',
					'air',
					'after'
				],
				title:    'Архив',
				info:     '',
				color:    'dark',
				type:     'archive',
				buttons:  [],
				pictures: [],
				contents: []
			},
			{
				stages:   [
					'air',
					'after'
				],
				title:    'Стенограмма',
				info:     '<p>Владимир Путин в прямом эфире подводит итоги года и отвечает на вопросы журналистов и жителей страны.</p>' +
					          '<p><strong>Д.Песков</strong>: Всем добрый день, здравствуйте!</p>',
				color:    'light',
				type:     'transcript',
				buttons:  [
					{
						title: 'Читать',
						view:  'transcript'
					}
				],
				pictures: []
			},
			{
				stages:   [
					'before',
					'air',
					'after'
				],
				title:    'Условия обработки персональных данных',
				info:     '',
				color:    'light',
				type:     'privacy',
				buttons:  [],
				pictures: [],
				contents: []
			},
			{
				stages:   [
					'before',
					'air',
					'after'
				],
				title:    'Правила проведения информационной кампании в рамках организации мероприятия «Прямая линия с Владимиром Путиным»',
				info:     '',
				color:    'light',
				type:     'rules',
				buttons:  [],
				pictures: [],
				contents: []
			},
		],
		isPopupOpened: false,
		popupPage: null,
	},
	getters:   {
		currentArticle: state => state.article,
		controlMenu:    state => state.sections
			.filter(item => item.stages.indexOf(state.stage) > -1 && !['privacy', 'rules'].includes(item.type))
			.map(item => ({
				title:    item.title,
				info:     item.info,
				color:    item.color,
				type:     item.type,
				subtype:  item.subtype,
				buttons:  item.buttons,
				pictures: item.pictures
			})),
		sections:       state => state.sections
			.filter(item => item.stages.indexOf(state.stage) > -1)
			.map(item => ({
				title:    item.title,
				color:    item.color,
				type:     item.type,
				subtype:  item.subtype,
				contents: item.contents,
				menu:     item.menu
			})),
		liveMenu:       state => state.sections
			?.find(section => section.type === 'live')
			?.menu || null,
		videoMenu:      state => state.sections
			?.find(section => section.type === 'videos')
			?.menu || null,
		isPopupOpened: state => state.isPopupOpened,
		popupPage: state => state.popupPage
	},
	mutations: {
		setStage(state, {stage}) {
			state.stage = stage;
		},
		setLivePlayer(state, {player}) {
			state.player.live = player;
		},
		setArticle(state, {type}) {
			state.article = type;
		},
		showPopup(state, {type, page}) {
			if (type) {
				document.body.classList.add('no-scroll');
				state.popupPage = page;
			} else {
				document.body.classList.remove('no-scroll');
				state.popupPage = null;
			}
			state.isPopupOpened = type;
		},
		setMenuActive(state, {type, item}) {
			state.sections.forEach(section => {
				if (section.type === type) {
					section.menu.forEach((option, index) => {
						if (!item) {
							option.isActive = index === 0;
						} else {
							option.isActive = item.id === option.id;
						}
					})
				}
			})
		}
	},
	actions:   {
		async setCurrentStage({commit}) {
			let now   = moment();
			let start = moment(process.env['LIVE_START'], 'DD-MM-YYYY HH:mm:ss');
			let end   = moment(process.env['LIVE_END'], 'DD-MM-YYYY HH:mm:ss');
			let stage = null;

			if (now.isBefore(start)) {
				stage = 'before';
			} else if (now.isAfter(start) && now.isBefore(end)) {
				stage = 'air';
			} else {
				stage = 'after';
			}

			commit('setStage', {
				stage
			})
		},
		async setArticle({commit}, {type}) {
			commit('setArticle', {
				type
			})
		},
		async setMenuActive({commit}, {type, item}) {
			commit('setMenuActive', {
				type,
				item
			})
		},
		async showPopup({commit}, {type, page}) {
			commit('showPopup', {
				type,
				page
			})
		},
	}
});